@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

html,
body,
#app {
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto',
    sans-serif;
    }
.content-wrapper {
    padding: 30px;
    background: white;
}

.content-wrapper:not(:last-child),
.content-wrapper:not(:only-of-type) {
    border-radius: 0;
}

.content-wrapper:first-of-type {
    border-radius: 35px 35px 0 0;
}

.content-wrapper:last-child {
    border-radius: 0 0 35px 35px;
}

.content-wrapper:only-of-type {
    border-radius: 35px;
}

.status-description {
    font-size: 40px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 1px;
    text-align: center;
    color: #4a7088;
    margin: 30px 0;
}
.flex-center {
    display: flex;
    justify-content: center;
}
.status-container {
    display: flex;
    align-items: center;
    height: 100%;
}
.redirect-btn {
    padding: 15px;
    border-radius: 15px;
    width: 100%;
    max-width: 380px;
    border: transparent;
    font-size: 24px;
    text-decoration: none;
    color: #fff;
    background-color: #2fadab;
    display: block;
    text-align: center;
}
.redirect-btn:hover {
    background-color: #23b9b7;
    cursor: pointer;
}
.title-description-small-img {
    max-width: 80px;
}