.accordion-item-container {
    margin-bottom: 2px;
    font-size: 16px;
    background: #f2f2f2 !important;
}

.accordion-item-container > div {
    min-height: 34px;
}

.subtitle {
    display: flex;
    align-items: center;
}

.subtitle img {
    margin-right: 15px;
}

.subtitle span {
    font-weight: 300;
    font-size: 24px;
}
