.error-msg {
    border-radius: 6px;
    background-color: rgba(255, 59, 48, 0.1);
    font-weight: bold;
    letter-spacing: 0.5px;
    color: #ff453a;
    padding: 8px 15px;
    text-align: left;
    font-size: 16px;
    margin-top: 8px;
}